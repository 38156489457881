import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ru from './locale/ru';
import en from './locale/en';
import kg from './locale/kg';
import es from './locale/es';

export const defaultNS = localStorage.getItem('language') || 'en';
export const resources = {
  en: { translation: en },
  es: { translation: es },
  ru: { translation: ru },
  kg: { translation: kg },
} as const;

i18next.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  lng: defaultNS,
  resources,
  debug: false,
});

export const widthOfContextMenu = {
  'ru': 180,
  'kg': 180,
  'en': 130,
  'es': 155
};

export default i18next;
