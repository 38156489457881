import React from 'react';
import { AxiosResponse } from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { UploadProps, message, Layout } from 'antd';
import Upload, { RcFile } from 'antd/es/upload';
import { useTranslation } from 'react-i18next';
import api from 'api';
import convert from 'api/convert';
import { IDocument, IDocumentInfo, IFolder, IWithWebkitPath } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchAvailableDocuments } from 'store/reducers/documentsCreator';
import { setNotes, setSelectedNote } from 'store/slices/dataNotes';
import fetchStatusDocuments from 'store/reducers/documentsSocketsCreator';
import { resetViewerSlice, setIsOpenViewer } from 'store/slices/viewerSlice';
import { setChoosenDocuments, setCurrentFolder, setDataDocumentTable, setDocuments, setDocumentsType, setEntityOfContextMenu, setSelectedDocument } from 'store/slices/dataDocumentsSlice';
import {
  setDisplayedDragger,
  setIsActiveContextMenu,
  setIsDeletingDocuments,
  setIsDownloadingDocuments,
  setIsLoadingProcess,
  setIsOpenDeleteEntityModal,
  setIsOpenMoveEntityModal,
  setIsOpenRenameEntityModal,
  setIsUploadingDocuments,
  setSelectedKeys,
  setIsOpenFileDetailsModal,
  setIsOpenShareEntityModal,
  setMoreInfoHidden
} from 'store/slices/windowStateSlice';
import { resetDataForPermissionTable, setFoldersForPermissions, setUserPermissions } from 'store/slices/dataPermissionsSlice';
import segregateToRootFolders from 'helpers/segregateToRootFolders';
import buildFoldersTree from 'helpers/buildFoldersTree';
import { PermissionsModal, NewFolderModal, ConfidentModal, MoveModal, DeleteModal, UploadModal, RenameModal } from 'components/Modals';
import DocumentDashboard from 'components/DocumentDashboard/DocumentDashboard';
import WebViewer from 'components/Webviewer/WebViewer';
import classes from './Documents.module.scss';
import DocumentManagement from 'components/DocumentManagement/DocumentManagement';
import DocumentTable from 'components/DocumentTable/DocumentTable';
import FileDetailsModal, { sectionMatching } from 'components/Modals/FileDetailsModal/FileDetailsModal';
import ShareModal from 'components/Modals/ShareModal/ShareModal';
import { setRoomAllChats } from 'store/slices/chatsSlice';
import { InboxOutlined } from '@ant-design/icons';
import { setDefaultActiveKey } from 'store/slices/dataAgreementsSlice';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import fetchDocumentToViewer from 'store/reducers/viewerCreator';

export default function Documents() {
  const [dragLeaveTimer, setDragLeaveTimer] = React.useState<NodeJS.Timeout>();
  const [fileList, setFileList] = React.useState<IWithWebkitPath[]>([]);
  const [selectedRowIds, setSelectedRowIds] = React.useState<string[]>([]);
  const [isVisibleUploadModal, setIsVisibleUploadModal] = React.useState(false);
  const [isAddedViaDragger, setIsAddedViaDragger] = React.useState(false);
  const [isOpenPermissionsModal, setIsOpenPermissionsModal] = React.useState(false);
  const [isOpenConfidentModal, setIsOpenConfidentModal] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [emptyText, setEmptyText] = React.useState<string>('fallback');
  
  const arrOfWebkitRelativePaths: string[] = fileList.map(file => file.webkitRelativePath ? file.webkitRelativePath : file.name);

  const { dataRoom, isLoadingRooms, isFolderCreateOpen } = useAppSelector((store) => store.dataRoom);
  const { isOpenViewer, documentUrl, isDocumentLoading, isDocumentError } = useAppSelector(state => state.viewer);
  const { userFoldersPermissions, userRootPermissions } = useAppSelector((store) => store.permissions);
  const { selectedRoom, isLoadingUserData, userData } = useAppSelector(state => state.userData);
  const { roomAllChats } = useAppSelector((state) => state.chat);
  const { documents, viewType, documentsType, currentFolder, choosenDocuments, selectedDocument,
    folders, entityOfContextMenu, dataDocumentTable, errorDocuments
  } = useAppSelector((store) => store.documents);
  const { isOpenDeleteEntityModal, isDeletingDocuments, isMovingDocument, displayedDragger,
    isOpenMoveEntityModal, isLoadingProcess, isOpenRenameEntityModal, isOpenShareEntityModal, isOpenFileDetailsModal
  } = useAppSelector((store) => store.windowState);
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const currentFolderId = useParams().folderId || null;
  const parentFolderID = currentFolder[0] ? currentFolder[0]?.id : null;
  
  const currentFolderWithPermissions = parentFolderID 
    ? userFoldersPermissions?.find(folder => folder.id === parentFolderID)
    : userRootPermissions;

  const isAvailableUploading = currentFolderWithPermissions?.permissions && currentFolderWithPermissions?.permissions?.includes('can_upload');
  
  const { t, i18n } = useTranslation();

  const fileNames = React.useMemo(() => dataDocumentTable.map((data) => data.name), [dataDocumentTable]);
  const uploadFilesCases = {
    files: {
      title: t('Documents.modals.upload.files'),
      isConfidential: false,
    },
    confidentialFiles: {
      title: t('Documents.modals.upload.confidentialFiles'),
      isConfidential: true,
    }
  };

  const listenPopstate = React.useCallback(() => dispatch(setIsOpenViewer(false)), []);

  React.useEffect(() => {
    const detailsSections = ['agreement', 'sign', 'note'];
    const document_id = localStorage.getItem('document_id') || searchParams.get('document_id');
    const modalSection = localStorage.getItem('modalSection') || searchParams.get('modalSection');
    const fetchToViewer = async (record: IDocument) => {
      navigate(`${window.location.pathname}?webviewer_file_id=${record.id}`);
      dispatch(setIsOpenViewer(true));
      await dispatch(fetchDocumentToViewer({
        token: record.token,
        extension: record.extension,
        is_agreement: false,
        id: dataRoom?.id,
        entity: record.id,
        action: record.is_confidential ? 'view_confident': 'view',
        locale: i18n.language
      }));
    }    
    const targetDocument = documents?.find(doc => doc.id === document_id);

    if(documents[0] && targetDocument && document_id && modalSection) {      
      const isDetails = detailsSections.includes(modalSection);
      const isWebviewer = modalSection === 'previewer';
      // const targetDocument = documents.find(doc => doc.id === document_id);
      if (targetDocument && isDetails) {
        dispatch(setSelectedDocument(targetDocument));
        dispatch(setIsOpenFileDetailsModal(true));
        const rightSection = sectionMatching[modalSection as keyof typeof sectionMatching];
        rightSection && dispatch(setDefaultActiveKey(rightSection));
        
        localStorage.removeItem('document_id');
        localStorage.removeItem('modalSection');
      } else if (targetDocument && isWebviewer) {
        fetchToViewer(targetDocument);

        localStorage.removeItem('document_id');
        localStorage.removeItem('modalSection');
      }
    }
  }, [documents, currentFolder])

  React.useEffect(() => {
    window.addEventListener('popstate' ,listenPopstate);
    return () => {
      window?.removeEventListener('popstate', listenPopstate);
    };
  }, []);

  React.useEffect(() => {
    dispatch(setSelectedKeys(['documents']));
    dispatch(setDocumentsType('files'));
    window.scroll(0,0);

    let promiseDocuments: any;
    if (dataRoom && userFoldersPermissions && dataRoom.id === params.room) {
    // if (dataRoom && dataRoom?.id === selectedRoom?.id && userFoldersPermissions) {      
      if ( currentFolderId ) {        
        const isUserHavePermissionsToView = currentFolderWithPermissions?.permissions?.length > 0;
        if (isUserHavePermissionsToView){
          const currentFolderIdx = currentFolder.findIndex(({ id }) => id === currentFolderId);
          dispatch(setCurrentFolder(currentFolder.slice(0, currentFolderIdx + 1)));          
          promiseDocuments = dispatch(fetchAvailableDocuments({ roomId: dataRoom.id, folderId: currentFolderId }));
        } else {
          setEmptyText('noPermissionToView');
          // dispatch(setDataDocumentTable([]));
          dispatch(setDocuments({ documents: [], folders: [] }));
        }
      } else if ( currentFolderId === null ) {
        dispatch(setCurrentFolder([]));
        promiseDocuments = dispatch(fetchAvailableDocuments({ roomId: dataRoom!.id, folderId: null }));
      }
    };

    reloadSelected();

    return () => {
      dispatch(setMoreInfoHidden(true));
      dispatch(setIsOpenViewer(false));
      
      setEmptyText('fallback');
      dispatch(resetDataForPermissionTable());

      promiseDocuments?.abort();
      // при возврате в документы всегда направляем в коревую папку      
      // dispatch(setCurrentFolder([]));
    }
  }, [dataRoom?.id, currentFolderId, userFoldersPermissions])

  React.useEffect(() => {
    if (fileList.length > 0 && !isVisibleUploadModal) {
      setIsAddedViaDragger(true)
      setIsVisibleUploadModal(true);
    };
  }, [fileList.length]);

  React.useEffect(() => {
    if (errorDocuments) message.error(t('Documents.error.receivingData'), 10)
  }, [errorDocuments]);

  const closeUpload = () => {
    setIsVisibleUploadModal(false);
    setIsAddedViaDragger(false);
    dispatch(setDocumentsType('files'));
    setFileList([]);
  };

  const onWebViewClose = () => {
    navigate(window.location.pathname);
    dispatch(setIsOpenViewer(false))
  };

  const reloadSelected = () => {
    setSelectedRowIds([]);
    dispatch(setChoosenDocuments([]));
    setSelectedRowKeys([]);
  };

  const resetNotes = () => {
    dispatch(setSelectedNote(''));
    dispatch(setNotes([]));
    dispatch(setMoreInfoHidden(true));
  }

  const handleUpload = async () => {
    dispatch(setIsUploadingDocuments(true));
    const currentFolderId = currentFolder[0] ? currentFolder[currentFolder.length - 1]?.id : null;

    const clientFolders = (fileList as IWithWebkitPath[]).filter((file) => file.webkitRelativePath?.includes('/'));
    const files = (fileList as IWithWebkitPath[]).filter((file) => !file.webkitRelativePath?.includes('/'));

    if(clientFolders.length > 0){
      const segregated = segregateToRootFolders(clientFolders);

      segregated.forEach(async (filePathList) => {
        
        const buildedFolders = buildFoldersTree(fileList, filePathList);
        const newFolderFilePath = filePathList[0];
        const newFolderPath = newFolderFilePath.join('/');
        const newFolderFirstFile = clientFolders.find((el) => el.webkitRelativePath === newFolderPath);

        const firstFolderFormData = new FormData();
        firstFolderFormData.append('json_template', JSON.stringify(buildedFolders));
        firstFolderFormData.append('room_id', dataRoom?.id!);
        firstFolderFormData.append('document_path', newFolderFilePath.slice(0, newFolderFilePath.length - 1).join('/'));
        firstFolderFormData.append('is_new_folder', `${true}`);
        if (currentFolderId) firstFolderFormData.append('parent_folder_id', `${currentFolderId}`);
        if (uploadFilesCases[documentsType].isConfidential) firstFolderFormData.append('is_confidential', `${true}`);
        firstFolderFormData.append('document', newFolderFirstFile as RcFile);

        try{
          const response = await api.uploadFolder({
            formData: firstFolderFormData,
            name: String(newFolderFirstFile?.name),
            action: uploadFilesCases[documentsType].isConfidential ? 'create_confident' : 'create',
          });
          const uploadedClienFolder: IFolder = {
            id: response.data.root_folder?.id,
            owner_id: userData?.id, 
            name: response.data.root_folder?.name || '',
            key: response.data.root_folder?.id,
            parent_folder_id: currentFolder || null,
            isOpenContextMenu: false,
            clicked: false,
            room_id: dataRoom?.id,
            permissions: currentFolderWithPermissions?.permissions, 
            type: 'folder',
          }
          const uploadedFolders: IFolder[] = [...folders, uploadedClienFolder];
          dispatch(fetchStatusDocuments({type: 'add', newFolder: uploadedClienFolder}));
          dispatch(setUserPermissions({documents: documents, folders: uploadedFolders, rootFolder: userRootPermissions}));
        }catch(err){
          console.log(err)
          message.error(t('Documents.error.uploadDocument'));
          if(filePathList === segregated[segregated.length - 1]){
            dispatch(setIsUploadingDocuments(false));
            setIsVisibleUploadModal(false);
          }
          return;
        };        

        await Promise.all(filePathList.map((filePath) => {
          if(filePathList[0] === filePath) return undefined;
          const path = filePath.join('/');
          const file = clientFolders.find((el) => el.webkitRelativePath === path);

          const formData = new FormData();
          formData.append('json_template', JSON.stringify(buildedFolders));
          formData.append('room_id', dataRoom?.id!);
          formData.append('document_path', filePath.slice(0, filePath.length - 1).join('/'));
          formData.append('is_new_folder', `${false}`);
          if (currentFolderId) formData.append('parent_folder_id', `${currentFolderId}`);
          if (uploadFilesCases[documentsType].isConfidential) formData.append('is_confidential', `${true}`);
          formData.append('document', file as RcFile);
          // currentFolderId && formData.append('folder_id', currentFolderId!);
          return api.uploadFolder({
            formData: formData,
            name: file?.name || '',
            action: uploadFilesCases[documentsType].isConfidential ? 'create_confident' : 'create',
          });
        }))
        .then(values => {
          message.success(t('Documents.success.uploadDocument'));
          closeUpload()
        })
        .catch((error) => {
          console.log(error)
          message.error(t('Documents.error.uploadDocument'));
          setIsVisibleUploadModal(false);
        }).finally(() => {
          dispatch(setIsUploadingDocuments(false));
        })
      })
    };

   if(files.length > 0) {
    await Promise.all(files.map((file) => {        
        const formData = new FormData();
        formData.append('document', file as RcFile);
        formData.append('room', dataRoom?.id!);
        if (currentFolderId) formData.append('folder_id', currentFolderId);
        if (uploadFilesCases[documentsType].isConfidential) formData.append('is_confidential', `${true}`);

        return api.uploadFile({
          formData: formData,
          id: dataRoom?.id!,
          name: file.name,
          action: uploadFilesCases[documentsType].isConfidential ? 'create_confident' : 'create',
        });
      }))
      .then(values => {
        message.success(t('Documents.success.uploadDocument'));
        dispatch(fetchStatusDocuments({type: 'add', newDocuments: values.map((response) => response.data) }));
        closeUpload();
      })
      .catch((error: any) => {
        setIsVisibleUploadModal(false);
        if (error?.response?.data?.message === 'Room has namesakes documents'){
          message.error(t('Documents.error.dublicate'));
          return
        }
        
        message.error(t('Documents.error.uploadDocument'));
      }).finally(() => {
        dispatch(setIsUploadingDocuments(false));
      })
   }
  };

  const handleDownloadCases = (payload: IDocumentInfo): Promise<AxiosResponse<Blob, any>> => {
    let response: Promise<AxiosResponse<any, any>>;
    if (payload.is_webview || payload.signing_status !== null) {
      response = convert.convertDocument({...payload, is_webview: false, locale: i18n.language})
    } else {
      response = api.downloadFile(payload);
    }
    return response as Promise<AxiosResponse<Blob, any>>
  };

  const handleDownload = async (is_webview: boolean) => {
    dispatch(setIsDownloadingDocuments(true))
    choosenDocuments.forEach(async ({ key: documentKey }) => {
      const documentInfo = (documentKey as string).split(' ');
      const documentToken = documentInfo[1];
      if(documentInfo[0] === 'folder') return;
            
      const { extension, name, id, is_confidential, signing_status} = documents.find(d => d.token === documentToken)!;   
      
      await handleDownloadCases({
          extension,
          token: documentToken,
          id: dataRoom?.id,
          entity: id,
          action: is_confidential ? 'download_confident' : 'download',
          is_agreement: false,
          is_webview,
          signing_status,
        })
        .then((res) => {
          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement('a');
          a.href = url;
          a.download = is_webview ? name.replace(/\.\w+$/, '.pdf') : name;
          document.body.appendChild(a);
          a.click();
          a.remove();
          message.success(t('Documents.success.downloadDocuments'))
        })
        .catch(() => {
          message.error(`${t('Documents.error.downloadFailed')} '${documentToken}'`);
        })
        .finally(() => {
          dispatch(setIsDownloadingDocuments(false));
          reloadSelected();
          // dataRoom && dispatch(setDocuments({documents: documents, folders: folders}));
        });
    });
  };

  const handleDeleteChoosenObjects = async () => {
    const arrFilesIds: string[] = [];
    const arrFilesNames: string[] = [];
    const arrFolderIds: string[] = [];
    const arrFolderNames: string[] = [];
    const arrFilesConfidentialIds: string[] = [];
    const arrFilesConfidentialNames: string[] = [];

    choosenDocuments.forEach((({ key: document }) => {    
      if (String(document).includes('folder')) {
        const folderId = String(document).split(' ')[1];
        const targetFolder = folders.find((folder) => folder.id === folderId);
        targetFolder && arrFolderIds.push(targetFolder?.id!)
        targetFolder && arrFolderNames.push(targetFolder?.name!)
      } else {        
        const documentId = String(document).split(' ')[2];        
        const targetDocument = documents.find((doc) => doc.id === documentId);

        const removedChats = roomAllChats.filter((chat) => chat.entity_id === targetDocument?.id!);
        dispatch(setRoomAllChats(removedChats));

        if(targetDocument?.is_confidential){
          targetDocument && arrFilesConfidentialIds.push(targetDocument?.id!);
          targetDocument && arrFilesConfidentialNames.push(targetDocument?.name!);
        } else{
          targetDocument && arrFilesIds.push(targetDocument?.id!);
          targetDocument && arrFilesNames.push(targetDocument?.name!);
        }
      }
    }));
    
    dispatch(setIsDeletingDocuments(true))
    try{
      arrFilesIds.length && await api.deleteDocuments(arrFilesIds, {
        room_id: dataRoom?.id!,
        action: 'delete',
        details: {
          documents_names: arrFilesNames.join(', '),
          documents_ids: arrFilesIds.join(', ')
        }
      });
      arrFilesConfidentialIds.length && await api.deleteDocuments(arrFilesConfidentialIds, {
        room_id: dataRoom?.id!,
        action: 'delete_confident',
        details: {
          documents_names: arrFilesConfidentialNames.join(', '),
          documents_ids: arrFilesConfidentialIds.join(', ')
        }
      });
      await Promise.all(arrFolderIds.map(async(id) => await api.deleteFolder(id)));
      const newFolders = folders.filter(folder => !arrFolderIds.includes(folder.id!));
      const newDocuments = documents.filter(doc => !arrFilesIds.includes(doc.id!) && !arrFilesConfidentialIds.includes(doc.id!));      
      dispatch(setDocuments({documents: newDocuments, folders: newFolders}));
      dispatch(setDataRoom({...dataRoom!, folders: newFolders}));
      dispatch(setFoldersForPermissions(newFolders));
      message.success(`${t('Documents.success.deleteObjects')}: ${[...arrFilesNames, ...arrFolderNames, ...arrFilesConfidentialNames].join(', ')}`);
    } catch (err) {
      message.error(`${t('Documents.error.deleteObjects')}: ${[...arrFilesNames, ...arrFolderNames, ...arrFilesConfidentialNames].join(', ')}`);
    } finally {
      reloadSelected();
      resetNotes();
      dispatch(setIsDeletingDocuments(false))
    }
  }

  const handleDrop = async (selectedFiles: string[], folder_id: string[], targetFolder?: string) => {    
    const folderIdWithNullCase = folder_id[0] === 'null' ? null : folder_id[0];
    const targetFolderPermissionsID = currentFolder[0] ? currentFolder[0].id : folder_id[0]

    const targetFolderPermissions = folderIdWithNullCase 
      ? userFoldersPermissions?.find(folder => folder.id === targetFolderPermissionsID)?.permissions
      : userRootPermissions.permissions

    const currentFolderPermissions = currentFolder[0] 
      ? userFoldersPermissions?.find(folder => folder.id === currentFolder[0].id)?.permissions
      : userRootPermissions.permissions

    const targetDocumentNames = documents.map(doc => selectedFiles.includes(doc.id) ? doc.name : '' )?.filter(doc => doc);
    const targetFolderName = folders.find(folder => folder.id === folder_id[0])?.name || targetFolder;    

    if (!targetFolderPermissions.includes('can_upload') || !currentFolderPermissions.includes('can_delete')) {
      message.error(`${t('Documents.error.accessToMove')}"${targetFolderName}"`)
      return;
    }
    
    dispatch(setIsLoadingProcess(true))
    try{
      await api.addDocumentsToFolder({document_ids: selectedFiles, folder_id: folderIdWithNullCase, room_id: dataRoom?.id!});
      const movedDocuments = documents.filter(document => !selectedFiles.includes(document.id!))
      dataRoom && dispatch(setDocuments({documents: movedDocuments, folders: folders}));

      message.success(
        `${t('Documents.success.successAddDocuments.before')} ${targetDocumentNames.join(', ')}
         ${t('Documents.success.successAddDocuments.after')} '${targetFolderName}'`
      );
    } catch (err) {
      message.error(
        `${t('Documents.error.failedAddDocuments.before')} ${targetDocumentNames.join(', ')}
         ${t('Documents.error.failedAddDocuments.after')} '${targetFolderName}'`
      );
    } finally {
      reloadSelected();
      resetNotes();
      closeEntityModal();
      dispatch(setIsLoadingProcess(false));
    }
  };

  const deleteTargetEntity = async () => {    
    dispatch(setIsDeletingDocuments(true));
    try {

      if (entityOfContextMenu?.type !== 'folder') {
        await api.deleteDocuments([entityOfContextMenu?.id!], {
          room_id: dataRoom?.id!,
          details: {
            documents_names: entityOfContextMenu?.id,
            documents_ids: entityOfContextMenu?.id!
          }
        });
        dispatch(setDocuments({
          documents: documents.filter(doc => doc.id !== entityOfContextMenu?.id),
          folders: folders
        }));
      } else {
        await api.deleteFolder(entityOfContextMenu?.id!);
        const foldersFilered = folders.filter(folder => folder.id !== entityOfContextMenu?.id);
        dispatch(setDocuments({
          documents: documents,
          folders: foldersFilered,
        }));
        dispatch(setDataRoom({...dataRoom!, folders: foldersFilered}));
        dispatch(setFoldersForPermissions(foldersFilered));
      }
      
      message.success(t('Documents.modals.delete.success'));
    } catch (e) {
      message.error(t('Documents.modals.delete.error'));
    } finally {
      dispatch(setIsDeletingDocuments(false));
      dispatch(setIsOpenDeleteEntityModal(false));
    }
  }

  const beforeUpload = (file: RcFile, allFiles: RcFile[]) => {
    if(fileNames.includes(file.name)){
      message.error(t('Documents.error.uniqueFiles'));
      return false;
    };
    const extension = file.name.match(/\.(\w+)$/)![1].toLowerCase();      
    if(extension === 'exe' || extension === 'bin'){
      message.error(t('Documents.error.notSupported'));
      return false;
    };
    const isAvailableToAddThisFile = !arrOfWebkitRelativePaths.includes(file.webkitRelativePath);
    isAvailableToAddThisFile && setFileList((prev) => [...prev, file]);
    return false;
  }

  const uploadProps: UploadProps = {
    multiple: true,
    onRemove: (file) => {
      setFileList(fileList.filter((currentFile)=> file.uid !== currentFile.uid))
    },
    beforeUpload,
    fileList,
  };

  const folderUploadProps: UploadProps = {
    multiple: true,
    // className: classes.fileItem,
    onRemove: (file) => {
      setFileList(fileList.filter((currentFile)=> file.uid !== currentFile.uid))
    },
    beforeUpload,
    directory: true,
    fileList: [],
  };

  const openContextModal = (targetEntity: IFolder | IDocument, openTargetModal: () => void) => {
    dispatch(setEntityOfContextMenu(targetEntity));
    openTargetModal();
    resetContextMenuField();
  };

  const closeEntityModal = () => {
    dispatch(setIsOpenFileDetailsModal(false));
    dispatch(setIsOpenDeleteEntityModal(false));
    dispatch(setIsOpenMoveEntityModal(false));
    dispatch(setEntityOfContextMenu(null));
    dispatch(setIsOpenRenameEntityModal(false));
    dispatch(setIsOpenShareEntityModal(false));
    dispatch(setNotes([]));
    dispatch(setDefaultActiveKey('documents_notes'));
    dispatch(resetViewerSlice());
    navigate(`${window.location.pathname}`);
  };

  const resetContextMenuField = () => {
    dispatch(setDataDocumentTable(dataDocumentTable.map(element => ({
      ...element,
      isOpenContextMenu: false,
    }))));
    dispatch(setIsActiveContextMenu(false));
  };
  
  const openContextMenu = (event: React.MouseEvent<HTMLTableRowElement | HTMLDivElement>, entity: IDocument | IFolder) => {            
    event.preventDefault();
    dispatch(setIsActiveContextMenu(true));
    dispatch(setDataDocumentTable(dataDocumentTable.map(element => ({
      ...element,
      isOpenContextMenu: element.id === entity.id ? true : false,
      // pageX: event.pageX,
    }))));
  }

  const preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDropEvent = (e: any) => {    
    dispatch(setDisplayedDragger(false));
    preventDefault(e);
  };

  const handleEnterEvent = (e: any) => {
    clearInterval(dragLeaveTimer);
    dispatch(setDisplayedDragger(true));
    preventDefault(e);
  };

  const onDragOver = (e: any) => {
    clearInterval(dragLeaveTimer);
    preventDefault(e);
  };

  const onDragLeave = (e: any) => {
    clearInterval(dragLeaveTimer);
    setDragLeaveTimer(setTimeout(() => dispatch(setDisplayedDragger(false)), 330));
    preventDefault(e);
  };

  const DocumentView = {
    Large: (
      <DocumentDashboard
        setSelectedRowKeys={setSelectedRowKeys}
        handleDropDocuments={handleDrop}
        isLoadingRooms={isLoadingRooms || isLoadingUserData}
        selectedRowIds={selectedRowIds}
        setSelectedRowIds={setSelectedRowIds}
        emptyText={emptyText}
        openContextModal={openContextModal}
        resetContextMenuField={resetContextMenuField}
        openContextMenu={openContextMenu}
      />
    ),
    List: (
      <DocumentTable 
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        handleDropDocuments={handleDrop}
        isLoadingRooms={isLoadingRooms || isLoadingUserData}
        onConfidentModalOpen={() => setIsOpenConfidentModal(true)}
        selectedRowIds={selectedRowIds}
        setSelectedRowIds={setSelectedRowIds}
        emptyText={emptyText}
        openContextModal={openContextModal}
        resetContextMenuField={resetContextMenuField}
        openContextMenu={openContextMenu}
      />
    )
  };
    
  return (
    <div
      className={classes.wrapper}
      onDrop={e => isAvailableUploading && handleDropEvent(e)}
      onDragOver={e => isAvailableUploading && onDragOver(e)}
      onDragEnter={e => isAvailableUploading && handleEnterEvent(e)}
      onDragLeave={e => isAvailableUploading && onDragLeave(e)}
    >
      <div className={classes.mainSpace}>
        <Layout style={{ borderRadius: 6, height: '100%', overflow: 'hidden' }}>
          
          <DocumentManagement
            setIsVisibleUploadModal={setIsVisibleUploadModal}
            setIsOpenPermissionsModal={setIsOpenPermissionsModal}
            handleDownload={handleDownload}
            handleDeleteChoosenObjects={handleDeleteChoosenObjects}
            reloadSelected={reloadSelected}
            currentFolderWithPermissions={currentFolderWithPermissions}
            setEmptyText={setEmptyText}
          />

          {DocumentView[viewType]}

        </Layout>
      </div>

      {isVisibleUploadModal && (
        <React.Suspense fallback={<div />}>
          <UploadModal
            open={isVisibleUploadModal}
            onCancel={closeUpload}
            entityName={dataRoom?.name}
            uploadProps={uploadProps}
            fileList={fileList}
            handleUpload={handleUpload}
            setFileList={setFileList}
            folderUploadProps={folderUploadProps}
            title={uploadFilesCases[documentsType]?.title}
            isAddedViaDragger={isAddedViaDragger}
            setIsAddedViaDragger={setIsAddedViaDragger}
          />
        </React.Suspense>
      )}

      {isFolderCreateOpen && (
        <React.Suspense fallback={<div />}>
          <NewFolderModal 
            roomId={dataRoom?.id!} 
            open={isFolderCreateOpen}
            resetNotes={resetNotes}
            reloadSelected={reloadSelected}
          />
        </React.Suspense>
      )}

      {isOpenViewer && (
        <React.Suspense fallback={<div />}>
          <WebViewer
            errorMessage={isDocumentError}
            onClose={onWebViewClose}
            isLoading={isDocumentLoading}
            url={documentUrl}
          />
        </React.Suspense>
      )}

      {isOpenPermissionsModal && (
        <React.Suspense fallback={<div />}>
          <PermissionsModal
            open={isOpenPermissionsModal}
            onCancel={() => setIsOpenPermissionsModal(false)}
            reloadSelected={reloadSelected}
          />
        </React.Suspense>
      )}

      {isOpenConfidentModal && (
        <React.Suspense fallback={<div />}>
          <ConfidentModal
            open={isOpenConfidentModal}
            onCancel={() => setIsOpenConfidentModal(false)}
            selectedDocument={selectedDocument!}
            roomId={selectedRoom?.id!}
            t={t}
          />
        </React.Suspense>
      )}

      <Upload.Dragger
        className={(displayedDragger && !isMovingDocument) ? classes.dropDownActive : classes.dropDownNone}

        openFileDialogOnClick={false}
        showUploadList={false}
        directory
        {...uploadProps}
        // accept='.doc,.xls,.xlsx,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>{t('Documents.modals.upload.dropText')}</p>
      </Upload.Dragger>

      <React.Suspense fallback={<div />}>
        <DeleteModal
          isOpen={isOpenDeleteEntityModal}
          onCancel={closeEntityModal}
          isLoading={isDeletingDocuments}
          submit={deleteTargetEntity}
          text={`${
            entityOfContextMenu?.type === 'folder'
              ? `${t('Documents.modals.delete.confirmFolder')}${entityOfContextMenu?.name}`
              : `${t('Documents.modals.delete.confirmFile')}${entityOfContextMenu?.name}.${entityOfContextMenu?.extension}`
          }?`}
          title={`${t('Documents.modals.delete.deletion')} 
            ${entityOfContextMenu?.type === 'folder' 
              ? t('Documents.modals.delete.folder')
              : t('Documents.modals.delete.file')
            }`
          }
        />
      </React.Suspense>

      <React.Suspense fallback={<div />}>
        {isOpenRenameEntityModal && (
          <RenameModal open={isOpenRenameEntityModal} onCancel={closeEntityModal} entity={entityOfContextMenu!} />
        )}
      </React.Suspense>

      <React.Suspense fallback={<div />}>
        {isOpenShareEntityModal && (
          <ShareModal open={isOpenShareEntityModal} onCancel={closeEntityModal} entity={entityOfContextMenu!} />
        )}
      </React.Suspense>

      <React.Suspense fallback={<div />}>
        <MoveModal
          isOpen={isOpenMoveEntityModal}
          onCancel={closeEntityModal}
          isLoading={isLoadingProcess}
          submit={(folder_id, targetFolder) => handleDrop([entityOfContextMenu?.id!], [folder_id], targetFolder)}
          title={t('Documents.modals.movingFile')}
          t={t}
        />
      </React.Suspense>

      <React.Suspense fallback={<div />}>
        {isOpenFileDetailsModal && (
          <FileDetailsModal isOpen={isOpenFileDetailsModal} onCancel={closeEntityModal} />
        )}
      </React.Suspense>
    </div>
  );
}
